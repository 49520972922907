'use strict'

angular.module('cb.quote-service', [])
	.factory('QuoteService', ["ApiService", function (ApiService) {

		var QuoteService = {

			newQuoteLine: function (data) {
				try {
					var url = '/webapi/api/v1/quote/quoteline/new';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			getQuoteLines: function (Id) {
				try {
					var url = '/webapi/api/v1/quote/quoteline/view/' + Id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			updateQuoteLine: function (data) {
				try {
					var url = '/webapi/api/v1/quote/quoteline/update';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			deleteQuoteLine: function (data) {
				try {
					var url = '/webapi/api/v1/quote/quoteline/delete';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			setQuoteOptionsForOrder: function (data) {
				try {
					var url = '/webapi/api/v1/quoteOption/SetQuoteOptionsForOrder';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			getQuoteOptionsByLeadId: function (leadId) {
				try {
					var url = '/webapi/api/v1/quoteOption/GetQuoteOptionsByLeadId/' + leadId;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			}
		};

		return QuoteService;
	}]);
